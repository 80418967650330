$(function(){
/*Импорт скриптов*/
// if (document.getElementById('status-current-check').checked) {
//     document.querySelector('.send__years').classList.add('visible-visibility');
//     console.log('sdfsddsfd');
    
// } else if (document.getElementById('status-new-check').checked) {
//     document.querySelector('.send__years').classList.remove('visible-visibility');
// };
$('.news__slider').slick({
  infinite: true,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 3000,
  speed: 900,
  prevArrow: $('.slider-switches__prev'),
  nextArrow: $('.slider-switches__next'),
});


let windowWidth = $(window).width();
if (windowWidth <= 900) {
  $(".news__cite-info").css({'height':($(".news__slider").height()-48+'px')});
} 


$('.member__all').click(function(){
  $('.member__item:nth-of-type(n+4)').slideToggle('medium', function() {
      if ($(this).is(':visible'))
          $(this).css('display','flex');
  });
  $('.member-all__arrow').toggleClass('member-all__arrow_up');
});

/*ТекстАреа*/

$(document).ready(function(){
    var maxCount = 2000;

    $("#counter").html(maxCount);

    $(".rus-symbols").keyup(function() {
    var revText = this.value.length;

        if (this.value.length > maxCount)
            {
            this.value = this.value.substr(0, maxCount);
            }
        var cnt = (maxCount - revText);
        if(cnt <= 0){$("#counter").html('0');}
        else {$("#counter").html(cnt);}

    });
});
});